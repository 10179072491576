import React, { useEffect, useState,useContext } from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Institution from '../../asserts/pngwing.com (2).png'
import { Typography } from '@mui/material'
import WestIcon from "@mui/icons-material/West";
import { NavLink } from 'react-router-dom';
// import Image from "../../../src/asserts/landing_page.jpg";
import { axiosInstance } from '../Api/instance';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../Context';
import { useNavigate } from 'react-router-dom';
export default function YearMCQ() {
    const Div = {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 4px",
      backgroundSize: "cover",
        backgroundColor:'white',
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "10px",
      padding: "20px",
    };
    const container = {
      background:
        "linear-gradient(45deg, #dee7fa,#ECE9FA, #f0f3fc,#e3f6fa), #FFFFFF 50% ", // Example gradient colors,
        height:"100vh"
     
    };
    const [data, setData] = useState([]);
    const {Endpoint}=useContext(UserContext);
    const email=localStorage.getItem("userMail");
    const Navigate=useNavigate();
    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async () => {
      try {
        const response = await axiosInstance.post(
          `get/U_ViewPmcqInstitution.php`,
          {
            userId:email
          }
          
        );
        if (response.status === 200) {
          if (response.data.message === "timeout") {
            Navigate('/signin'); 
          }

            const obj = response.data.map((data) => ({
              img: `https://vebbox.in/Govt_vebbox/controllers/api/admin/upload/${data.img}`,
              name: data.institution_name,
              path: `/instruction/${data.sno}`,
              sno:data.sno
            }));
            console.log(obj);
            setData(obj);
         
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
  return (
    <div>
         
        <Container fluid  style={container}>
            <Row style={{justifyContent:"center",padding:20}}>
            <Typography style={{fontSize:"20px",fontWeight:600,textAlign:"center",paddingBottom:"20px"}}><NavLink to="/home" style={{color:"black"}}><WestIcon/></NavLink>&nbsp;Previous Year MCQ</Typography>
                {
                    data.map((d)=>{
                        return(
                            <>
                             <Col xs={12} sm={12} md={6} lg={3} xl={3} style={{justifyContent:"center",alignItems:"center",marginBottom:"20px"}} >
                    <NavLink to={d.path} style={{color:"black",textDecoration:"none"}}>
                    <div style={Div}>
                        <div>
                            <img src={d.img} height="60px"/>
                        </div>
                        <div style={{paddingTop:"20px"}}>
                            <Typography style={{fontWeight:600}}>{d.name}</Typography>
                        </div>
                    </div>
                    </NavLink>
                </Col> 
                            </>
                        )
                    })
                }
            </Row>
        </Container>
    </div>
  )
}
