import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TodayIcon from "@mui/icons-material/Today";
import image from "./google-icon.svg";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Image from '../../asserts/signup1.png'
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../Api/instance";
// import GoogleButton from 'react-google-button'
import {auth } from "../../FirebaseConfig";
import  { UserContext } from "../../Context";
import { useContext } from "react";
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
  

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width:"100%",
  },
  signInButton: {
    width: "100%", 
    height: "40px",
    backgroundColor: "#3857B9",
    color: "#fff",
    marginTop: "10px",
    textDecoration:"none"
  },
  googleButton: {
    width: "96%", 
    height: "40px",
    backgroundColor: "#fff",
    color: "#3857B9",
    marginTop: "10px", 
  },
  
};

const Signup= () => {
  const Navigate=useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [gender, setGender] = useState(""); // Default value
  const [qualification, setQualification] = useState("");
  const [pass,setPass]=useState("");
  const [conpass,setconpass]=useState("");
  const [name,setname]=useState("");
  const [email,setemail]=useState("");
  const [number,setnumber]=useState("");
  const [date,setdate]=useState("");
  const [month,setmonth]=useState("");
  const [year,setyear]=useState("");
  const [userId,setuserId]=useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConPassword = () => setShowConPassword((show) => !show);
  const {Endpoint}=useContext(UserContext);
  // const [profile,setprofile]=useContext(UserContext);


  const nameChange=(e)=>{  
    const value = e.target.value;
    const regex = /^[A-Za-z\s]*$/;

    if (regex.test(value)) {
      setname(value);
    }
  }
  const emailChange=(e)=>{
    setemail(e.target.value);
  } 
  const numberChange=(e)=>{
    const value = e.target.value;

    // Regular expression to allow only digits
    const regex = /^[0-9\b]+$/;

    // Check if the input is a number and the length is 10 or less
    if (regex.test(value) && value.length <= 10) {
      setnumber(value);
    }
  }
  const dateChange=(e)=>{
    setdate(e.target.value);
  }
  const monthChange=(e)=>{
    setmonth(e.target.value);
  }
  const yearChange=(e)=>{
    setyear(e.target.value);
  }
  const handleChange = (event) => {
    setPass(event.target.value);
  };
  const handleChangeConpass = (event) => {
    setconpass(event.target.value);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConPassword = (event) => {
    event.preventDefault();
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleQualificationChange = (event) => {
    setQualification(event.target.value);
  };

  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  const currentYear = new Date().getFullYear();
const startYear = 1990;

const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index);


const handleClickOpen = async () => {
  try {
    if (pass.length >= 6 && conpass.length >= 6) {
      if (pass === conpass) {
        const response = await axiosInstance.post(
          `post/U_InsertStudent.php`,
          {
            username: name,
            email: email,
            password: pass,
            mobileno: number,
            dob: [year, month, date],
            gender: gender,
            qualification: qualification,
          }
        );
        
        Navigate("/signin");
      } else {
        alert("Passwords do not match");
      }
    } else {
      document.getElementById("pass").innerHTML="Password and Confirm Password must be at least 6 characters long";
      document.getElementById("pass").style.color="red";
    }
  } catch (error) {
    console.error("Error:", error);
    alert("Enter Valid Credentials");
  }
};

const provider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const signedInUser = result.user;
      
      
      localStorage.setItem("username",signedInUser.displayName);
      const username=localStorage.getItem('username');
      localStorage.setItem("userMail",signedInUser.email)
      localStorage.setItem("profile",signedInUser.photoURL);
      const userid=localStorage.getItem('userMail');
      
      
      let payload = {
        username:username,
        email:userid,
        mobileno: "", 
        gender: "", 
        qualification: "", 
        password: "", 
        dob: "", 
      };
      try {
        
            const response = await axiosInstance.post(
              `post/U_InsertStudent.php`,
              payload
            );
            Navigate("/signin");
            
            
          
      } catch (error) {
        console.error("Error:", error);
        alert("Enter Valid Credentials");
      }
      
 
      
    } catch (error) {
      console.error("Google Sign-In Error", error);
    }
  };

  return (
    <Grid container component="main" style={{ height: "50vh", }}>
      <Grid item xs={12} sm={6}>
        <Paper
          
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{ marginBottom: "16px", color: "#3857B9",marginTop:"30px",fontWeight:600 }}
          >
            Signup
          </Typography>
          <form style={{ width: "100%", marginTop: "8px", padding: "10px" }}>
            <Typography style={{ color: "#3857B9",marginBottom:"10px",fontWeight:600 }}>
              Full Name <Person2Icon style={{ color: "#3857B9",fontSize:"19px",marginTop:"-4px" }} />
            </Typography>
            <TextField
      fullWidth
      id="fullName"
      size="small"
      onChange={nameChange}
      value={name}
      required
      style={{ marginBottom: '10px' }}
      // helperText="Only letters are allowed."
      error={!/^[A-Za-z\s]*$/.test(name)}
    />
            <Typography style={{ color: "#3857B9",marginBottom:"10px",fontWeight:600 }}>
              Email <EmailIcon style={{ color: "#3857B9",fontSize:"16px",marginTop:"-2px" }} />
            </Typography>
            <TextField
              fullWidth
              id="email"
              size="small"
              style={{ marginBottom: "10px" }}
              placeholder="Enter with Email"
              onChange={emailChange}
              required
              value={email}
            />

            <Grid container spacing={2} >
            <Grid item xs={6}>
        <Typography style={{ color: "#3857B9",fontWeight:600 }}>
          Password <LockIcon style={{ color: "#3857B9",fontSize:"14px",marginTop:"-3px" }} />
        </Typography>
        <FormControl sx={{ width: "100%", marginBottom: "8px" }} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            value={pass}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  // aria-label={`toggle ${label.toLowerCase()} password visibility`}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff style={{ color: "#3857B9" }} />
                  ) : (
                    <Visibility style={{ color: "#3857B9" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <p id="pass"></p>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ color: "#3857B9",fontWeight:600 }}>
          Confirm Password <LockIcon style={{ color: "#3857B9",fontSize:"14px",marginTop:"-3px" }} />
        </Typography>
        <FormControl sx={{ width: "100%", marginBottom: "8px" }} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-password"
            type={showConPassword ? "text" : "password"}
            onChange={handleChangeConpass}
            value={conpass}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  // aria-label={`toggle ${label.toLowerCase()} password visibility`}
                  onClick={handleClickShowConPassword}
                  onMouseDown={handleMouseDownConPassword}
                  edge="end"
                >
                  {showConPassword ? (
                    <VisibilityOff style={{ color: "#3857B9" }} />
                  ) : (
                    <Visibility style={{ color: "#3857B9" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <p id="conpass"></p>
        </FormControl>
      </Grid>
            </Grid>

            <Typography style={{ color: "#3857B9",marginBottom:"10px",fontWeight:600 }}>
              Mobile Number <PhoneIphoneIcon style={{ color: "#3857B9",fontSize:"18px",marginTop:"-2px" }} />
            </Typography>
            <TextField
      fullWidth
      id="mobileNumber"
      size="small"
      onChange={numberChange}
      value={number}
      required
      style={{ marginBottom: '10px' }}
      helperText={number.length !== 10 ? "Enter a valid 10-digit mobile number." : ""}
      error={number.length !== 10 && number.length > 0}
    />

            <Typography style={{ color: "#3857B9",marginBottom:"10px",fontWeight:600 }}>
              Date of Birth <TodayIcon style={{ color: "#3857B9",fontSize:"16px",marginTop:"-4px" }} />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
              <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" size="small">Date</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={date}
          label="Date"
          size="small"
          onChange={dateChange}
        >
           {Array.from({ length: 31 }, (_, index) => (
        <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
      ))}
        </Select>
      </FormControl>
              </Grid>
              <Grid item xs={4}>
              <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" size="small">Month</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={month}
          label="Month"
          size="small"
          onChange={monthChange}
        >
            {months.map((monthName, index) => (
    <MenuItem key={index + 1} value={index + 1}>{monthName}</MenuItem>
  ))}
        </Select>
      </FormControl>
              </Grid>
              <Grid item xs={4}>
              <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" size="small">Year</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={year}
          label="Year"
          size="small"
          onChange={yearChange}
        >
           {years.map((yearValue) => (
    <MenuItem key={yearValue} value={yearValue}>{yearValue}</MenuItem>
  ))}
        </Select>
      </FormControl>
              </Grid>
            </Grid>

            <Typography style={{ color: "#3857B9",marginTop:"10px",fontWeight:600 }}>
              Gender <PersonSearchIcon style={{ color: "#3857B9",fontSize:"18px",marginTop:"-4px" }} />
            </Typography>
            <RadioGroup
              row
              aria-label="gender"
              name="gender"
              value={gender}
              onChange={handleGenderChange}
              style={{ marginBottom: "16px" }}
            >
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Male"
                style={{ color: "#3857B9" }}
              />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
                style={{ color: "#3857B9" }}
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
                style={{ color: "#3857B9" }}
              />
            </RadioGroup>

            {/* Qualification */}
            <Typography style={{ color: "#3857B9",marginBottom:"10px",fontWeight:600 }}>
              Qualification <AutoStoriesIcon style={{fontSize:"16px",marginTop:"-4px",marginLeft:'5px'}}/>
            </Typography>
            <Select
              fullWidth
              id="qualification"
              value={qualification}
              onChange={handleQualificationChange}
              placeholder=" Choose Your Qualification"
              style={{ marginBottom: "8px", maxHeight: "40px" }}
            >
              <MenuItem value="">Choose Your Qualification</MenuItem>
              <MenuItem value="B.sc nursing ">B.sc  </MenuItem>
              <MenuItem value="M.sc nursing ">M.sc  </MenuItem>
              <MenuItem value="Others">Others</MenuItem>
              {/* Add more qualification options as needed */}
            </Select>

            {/* Signup and Signin buttons with styles */}
            <div style={styles.buttonContainer}>
              <Button variant="contained"   style={styles.signInButton} onClick={handleClickOpen}>
                Sign up
              </Button>
            </div>
           
          </form>
          <Typography>Have an Account ? <NavLink to='/signin'> Signin</NavLink></Typography>
           {/* or 
          <div style={styles.buttonContainer}>
            <Button variant="contained" style={styles.googleButton} onClick={() => { signInWithGoogle()}}>
              <ListItemIcon>
                <img
                  src={image}
                  alt="Google Icon"
                  style={{ width: "20px", marginRight: "8px" }}
                />
              </ListItemIcon>
              Continue with Google
            </Button>
          </div> */}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} style={{ background: "#fefbe9",paddingTop:"40px" }}>
        {/* Your image goes here */}
        <img
          src={Image}
          alt="Your Image"
          style={{
            width: "100%",
            
          }}
        />
      </Grid>
    </Grid>


  );
};
export default Signup;
