import React, { useEffect, useContext, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import styles from "./style";
import MyComponent from "../Category";
import AchivementMain from '../Achivements/index';
import Footer from "../footer";
import { NavLink, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { UserContext } from "../../Context";
import { axiosInstance } from "../Api/instance";
// import Video from '../../asserts/bgVideo.mp4';
import './style.css'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const ButtonContainer = () => {
  const Navigate = useNavigate();
  return (
    <div>
      <Container fluid maxWidth="sm" style={styles.buttonContainer}>
        
        <Button
        className="btn"
          onClick={() => Navigate('/plan')}
          variant="contained"
          style={{ ...styles.premiumButton }}
        >
          Premium
        </Button>
        
      </Container>
    </div>
  );
};

const Home = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState("");
  const { Endpoint} = useContext(UserContext);
  const email = localStorage.getItem("userMail");
  const expiry_date = localStorage.getItem("expiry_date");
  const Navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    try {
      const response = await axiosInstance.post(
        `get/U_ViewAds.php`,
        {
          userId: email,
          category: "Home",
        }
      );
      if (response.status === 200) {
        if (response.data.message === "timeout") {
            Navigate('/signin'); 
        }
        
          const data=response.data[0].attachment_file;
          setDatas(data);
          console.log(data);
       
     
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  

  const checkPlanExpiry = () => {
    if (expiry_date) { 
      
      const currentDate = new Date().toISOString().split('T')[0];
      const alertShown = localStorage.getItem("alertShown");

      if (!alertShown && currentDate > expiry_date) {
        alert("Your plan has expired. Please purchase a new plan.");
        localStorage.setItem("alertShown", "true"); 
      }
    }
  };

  useEffect(() => {
    checkPlanExpiry(); 
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClickOpen();
  }, []);

  return (
    <div >
      <Box className="maincontainer">
        <CssBaseline />
       
        <Container fluid  maxWidth="xl">
          <div style={{display:"flex",justifyContent:"flex-end",width:"90%"}}> 
          <p className="contentContainer">
            INVEST IN YOUR <span >DREAMS</span> GRIND<br /> NOW SHINE LATER
          </p><br/>
          
          </div>
        <div style={{display:"flex",justifyContent:"flex-end",width:"80%"}}>
        <ButtonContainer />
        </div>
        </Container>
       
      </Box>
      <div id="category">
        <MyComponent />
      </div>
      <div id="ad">
        <AchivementMain />
      </div>
      <div >
        <Footer />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* {datas && ( */}
            <img src={`https://vebbox.in/Govt_vebbox/controllers/api/admin/upload/${datas}`} height="500px" />
          {/* // )} */}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default Home;
