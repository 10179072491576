import { Padding } from "@mui/icons-material";
import Image from "../../../src/asserts/Homebg.jpg";

const styles = {
  buttonContainer: {
    // display: "flex",
    // justifyContent: "flex-end",
    padding:0,
    // width:"100%",
  },
  startButton: {
    backgroundColor: "#3857B9",
    color: "white",
  },
  premiumButton: {
    backgroundColor: "#3857B9",
    color: "white",
    fontFamily: 'Source',
  },
  
};

export default styles;
