import { Typography } from '@mui/material'
import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import BG from '../../asserts/bg2.png'
import CheckIcon from '@mui/icons-material/Check';
import Mission from '../../asserts/mission.webp'
import Vision from '../../asserts/mission.png'
import Update from '../../asserts/nursing_update.jpg'
import Nursing from '../../asserts/nursing_service.png'
import Footer from '../footer';
import Tick from '../../asserts/49827_check_done_icon.png'
// import UpdateVideo from '../../asserts/aboutbg.mp4'; // Path to your video file

export default function AboutUs() {
  const MainContainer={
    background:`url(${BG})`,
    height:"40vh",
    backgroundSize:"cover",
  }
  const MainCol={
    display:"flex",
    backgroundColor:"rgba(255, 255, 255, 0.301)",
    height:"70vh",
    width:"98vw",
    alignItems:"center"
  }
  const MainDiv={
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width:"99vw",
  }
  const ColStyle={
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }
  return (
    <div>
      <Container fluid style={MainContainer}>
        <Row>
          <Col style={MainCol}>
            <div style={MainDiv}>
            <div>
                <Typography variant='h5' style={{textAlign:"center",fontWeight:500,paddingBottom:"200px"}}>We are team of talented developers making <br/> Excellent Solutions to business<br/></Typography>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container  style={{marginTop:"30px"}}>
        <Row>
          <Col xs={{order:2}} sm={{order:2}}  lg={{order:1}} xl={{order:1}} md={{ order: 1 }} style={ColStyle}>
            <div>
              <Typography variant='h5' style={{paddingBottom:"20px",fontWeight:600}}>Our Mission</Typography>
              <Typography><img src={Tick}/>&nbsp; Our mission is to enhance business growth of our customers with creative design, development and to deliver market defining high quality solutions that create value and reliable competitive advantage to customers around the globe. </Typography>
              {/* <Typography><img src={Tick}/>&nbsp; Enterprise is the way,  </Typography>
              <Typography><img src={Tick}/>&nbsp; Hard work is the solution </Typography> */}
            </div>
          </Col>
          <Col  xs={12} sm={12} md={6}  lg={{order:2}} xl={{order:2}} style={ColStyle}>
          <div>
          <img src={Mission} height="200px"/>
          </div>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{backgroundColor:"#E8EBFF",marginTop:"30px"}}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} style={ColStyle}>
            <div>
              <img src={Vision} height="300px"/>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} style={ColStyle}>
          <div style={{marginTop:"40px"}}>
              <Typography variant='h5' style={{paddingBottom:"20px",fontWeight:600}}>About Us</Typography>
              <div style={{width:"90%"}}>
              <ul style={{textAlign:"justify",listStyleType:"none"}}>
                <li>
                  <img src={Tick}/>&nbsp;We are consummate custom software Development company delivering splendid business IT Solutions and related services to customers across the globe. Our development services are led by our dedicated and passionate team to provide best industry practices combined with technology expertise and business domain knowledge to drive digital transformation. </li><br/>
                <li>
                <img src={Tick}/>&nbsp;Our proficiency in understanding business challenges and professional competence allows us to create a better experience for our customers. Our efforts focus on concept, design, development and re-engineering of web businesses. We are working closely with software majors on new applications  
                </li>
              </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid style={{ margin: 0, padding: 0, position: 'relative', height: '40vh', overflow: 'hidden' }}>
      <Row style={{ height: '100%', width: '100%' }}>
        <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transform: 'translate(-50%, -50%)',
            zIndex: -1,
          }}
        >
          <source src={UpdateVideo} type="video/mp4" />
          <source src={UpdateVideo} type="video/webm" />
          <source src={UpdateVideo} type="video/ogg" />
        </video>
      </Row>
      
    </Container> */}
      {/* <Container fluid style={{marginTop:"30px"}}>
        <Row>
          <Col>
            <div style={{width:"88%"}}>
              <ul style={{listStyleType:"none",textAlign:"justify"}}>
                <li>
                <img src={Tick}/>&nbsp;Nursing Update, a unit of Venba Coaching Institute, started as a Youtube channel and received overwhelming response prompting its conversion into a full-fledged coaching institute in 2021. Since then, it has achieved significant success in providing quality coaching and training to nursing students, including high pass rates and successful placements in top healthcare institutions.
                </li><br/>
                <li>
                <img src={Tick}/>&nbsp;The institute has also gained a strong reputation for its dedicated faculty, modern teaching methods, and student-focused approach.Our platform provides unparalleled information and expertise, with a forward-thinking outlook, practicality, robust functionalities, and personalized guidance and support throughout the journey of preparation
                </li><br/>
                <li>
                <img src={Tick}/>&nbsp;As a responsible provider of education to future nurses, our institute has ensured to constantly upgrade our knowledge and practices. We have revamped our curriculum and methods of teaching, and redefined our standards to produce more qualified and skilled nurses who can add value to the healthcare system. Our commitment to providing the best education and training will create a positive impact in enhancing the quality of patient care and improving the overall health outcomes.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container fluid >
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} style={ColStyle}>
          <div>
            <img src={Nursing} height="300px"/>
          </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} style={ColStyle}>
          <div style={{marginTop:"40px"}}>
              <Typography variant='h5' style={{paddingBottom:"20px",fontWeight:600}}>Our Services</Typography>
              <div style={{width:"90%"}}>
              <ul style={{textAlign:"justify",listStyleType:"none"}}>
                <li> <img src={Tick}/>&nbsp;We rely on our technological expertise and specialized industry experience to develop any type of web, mobile, desktop, and hybrid app per your business requirements.   </li><br/>
                <li>
                <img src={Tick}/>&nbsp;Our application maintenance and modernization services are designed to ensure the scalability, performance, and sustainability of your entire software infrastructure as your business grows. 
                </li><br/>
                <li>
                <img src={Tick}/>&nbsp;Comprehensive quality assurance is built into our custom software service model, but we can also provide on-demand QA and a suite of functional and usability software tests upon request.
                </li><br/>
                <li>
                <img src={Tick}/>&nbsp;We build and implement custom APIs for all breeds of applications, helping to add functionality to your software systems and facilitate communication between your apps and others.
                </li>
              </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </div>
  )
}
