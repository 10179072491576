// HomeStyles.js
import { Padding } from '@mui/icons-material';
import Bg from '../../asserts/vbx.jpg'


const styles = {
    container: {
      backgroundImage:`url(${Bg})`,
      backgroundSize: 'cover',
      width:"100%",
      height:"95vh",
      display: "flex",
      flexDirection: "column",
      paddingTop:"100px",
      overflowX:"hidden",
      '@media (max-width:600px)': {
        height:"50vh",
        width:"100%",
        // Padding:'150px',
        backgroundColor:'red',
    },
      
    },
    contentContainer: {
      marginTop:"5%",
     textAlign:'center',
     
    },
//     tt:{
//       fontSize:'30px',
// '@media(max-width:600px)': {
//     //  padding:'40px',
//       backgroundColor:'red',
//       color:'red'
//   },
    // },
    buttonContainer: {
      marginTop:"5%",
    },
    button: {
     
      height: "50px",

    },
    startButton: {
      backgroundColor: "#E1EEdd",
      color: "#3857B9",
     
    },
    premiumButton: {
      backgroundColor: "#3857B9",
      color: "white",
    },
  };
  
  export default styles;
  